import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Muscle Snatch 3×4 & Overhead Band Pull Aparts 3×10`}</p>
    <p>{`then,`}</p>
    <p>{`Power Snatch 4×4\\@75%1RM`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`15-OHS’s (95/65)(RX+ 115/75)`}</p>
    <p>{`15-T2B`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      